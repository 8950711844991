<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" @datachange="getList" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            type="search"
            :comboItems="useFlagItems"
            itemText="codeName"
            itemValue="code"
            label="사용여부"
            name="useFlag"
            v-model="searchParam.useFlag"
            @datachange="getList"
            >
          </c-select>
        </div> -->
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-card title="폴더 (폴더명으로 정렬됩니다)" class="cardClassDetailForm folder-tree" :height="treeHeight">
          <template slot="card-button">
            <q-btn-group outline >
              <!--저장-->
              <c-btn v-if="editable" label="ROOT 폴더 추가" icon="add" @btnClicked="addFolder" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <q-tree
              :nodes="dataTree"
              node-key="opmFolderId"
              no-nodes-label="생성된 폴더가 없습니다."
              selected-color="primary"
              :default-expand-all="true"
            >
              <template v-slot:header-root="prop">
                <div class="row items-center">
                  <q-icon :name="'folder'" color="orange" size="24px" class="q-mr-sm" />
                  <div>
                    <div v-on:click="rowClick(prop.node)">{{ prop.node.folderName }}</div>
                      <q-menu
                        v-if="editable"
                        transition-show="scale"
                        transition-hide="scale"
                        touch-position
                        context-menu
                      >
                        <q-list dense class="topTabContext">
                          <q-item class="context-title">
                            <q-item-section><q-icon :name="'folder'" color="orange" size="20px" class="q-mr-sm" /> {{ prop.node.folderName }}</q-item-section>
                          </q-item>
                          <q-item clickable v-close-popup v-on:click="addFolder(prop.node)">
                            <q-item-section><q-icon class="text-dark" name="add" /> 하위 폴더 추가 </q-item-section>
                          </q-item>
                          <q-item clickable v-close-popup v-on:click="changeFolder(prop.node)">
                            <q-item-section><q-icon class="text-dark" name="published_with_changes" /> 폴더명 변경 </q-item-section>
                          </q-item>
                          <q-item clickable v-close-popup v-on:click="removeFolder(prop.node)">
                            <q-item-section><q-icon class="text-dark" name="delete" /> 폴더 삭제 </q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                  </div>
                </div>
              </template>
            </q-tree>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <!-- 지침서 목록 -->
        <c-table
          title="지침서 목록"
          rowKey="id"
          :columns="gridProcedure.columns"
          :data="gridProcedure.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <template slot="table-chip">
            <q-btn-group outline >
              <q-chip outline square>
                  <q-avatar icon="bookmark" color="green" text-color="white" /> 
                  {{ $language('폴더명') + " : " + folderName}}  <!-- 폴더명 -->
              </q-chip>
            </q-btn-group>
          </template>
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <!-- 지침서 등록 -->
            <q-btn-group outline >
              <c-btn v-if="editable && folderName" label="지침서 등록" icon="add" @btnClicked="procedureAdd" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">{{ dTitle }}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="hiddenFolderName" autofocus/>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="취소" @click="cancelFolderName" />
          <q-btn flat label="저장" @click="saveFolderName" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import arrayToTree from 'array-to-tree';
export default {
  name: 'oper-pro-management',

  data() {
    return {
      treeHeight: '',
      dTitle: '폴더',
      saveType: '',
      hiddenFolderName: '',
      prompt: false,
      gridFolder: {
        data: [],
      },
      saveData: {
        opmFolderId: '',
        opmParentFolderId: '',
        folderName: '',
        plantCd: '',
        regUserId: '',
        chgUserId: '',
      },
      gridProcedure: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: '사업장',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'folderDetailName',
            field: 'folderDetailName',
            // 절차서 제목
            label: '절차서 제목',
            type: "link",
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'standardDate',
            field: 'standardDate',
            // 기준일
            label: '기준일',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
        ],
        data: [],
        height: '362px'
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      useFlagItems: [],
      searchUrl: '',
      saveUrl: '',
      deleteUrl: '',
      folderName: '',
      opmFolderId: ''
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    dataTree() {
      let val = this.convertTree(this.gridFolder.data);
      console.log(val)
      return val ? val : [];
    },
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.treeHeight = (window.innerHeight - (270)) + 'px';
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.opm.folder.list.url;
      this.saveUrl = transactionConfig.sop.opm.folder.insert.url;
      this.deleteUrl = transactionConfig.sop.opm.folder.delete.url;
      this.listDetailUrl = selectConfig.sop.opm.folderDetail.list.url;

      this.getList();
    },
    addFolder(row) {
      if (!row) {
        this.dTitle = this.$language('최상위 폴더 추가');
        this.saveData = {
          opmParentFolderId: '',
          plantCd: this.searchParam.plantCd,
        }
      } else {
        this.dTitle = this.$language('하위폴더생성');
        this.saveData = {
          opmParentFolderId: row.opmFolderId,
          plantCd: this.searchParam.plantCd,
        }
      }
      this.saveType = 'POST'
      this.prompt = true;
      this.hiddenFolderName = '';
    },
    changeFolder(row) {
      this.dTitle = this.$language('폴더명 변경');
      this.saveType = 'PUT'
      this.prompt = true;
      this.hiddenFolderName = row.folderName;
      this.saveData = {
        opmFolderId: row.opmFolderId,
        plantCd: this.searchParam.plantCd,
      }
    },
    removeFolder(row) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '하위폴더도 모두 삭제됩니다. 삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, row.opmFolderId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.getList();
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    cancelFolderName() {
      this.hiddenFolderName = '';
      this.prompt = false;
    },
    saveFolderName() {
      this.prompt = false;

      this.$http.url = this.saveUrl;
      this.$http.type = this.saveType;
      this.$http.param = {          
        opmFolderId: this.saveData.opmFolderId,
        opmParentFolderId: this.saveData.opmParentFolderId,
        plantCd: this.saveData.plantCd,
        folderName: this.hiddenFolderName,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
      };
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.getList();
        this.hiddenFolderName = '';
      });
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridFolder.data = _result.data;
        this.opmFolderId = '';
        this.folderName = '';
        this.gridProcedure.data = []
      },)
    },
    rowClick(row) {
      this.opmFolderId = row.opmFolderId;
      this.folderName = row.folderName;
      this.$http.url = this.$format(this.listDetailUrl, row.opmFolderId);
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridProcedure.data = _result.data
      },)
    },
    procedureAdd() {
      this.linkClick(null);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "공정안전보고서 상세"; // 공정안전보고서 상세
      this.popupOptions.param = {
        opmFolderDetailId: row ? row.opmFolderDetailId : '' ,
        opmFolderId: this.opmFolderId ? this.opmFolderId : '' ,
      };
      this.popupOptions.target = () =>
        import(`${"./processSafetyReportDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.closeCallback = this.closeDetailPopup;
    },  
    closeDetailPopup() {
        
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.rowClick({ opmFolderId: this.opmFolderId, folderName: this.folderName})
    },
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'opmParentFolderId',
        customID: 'opmFolderId',
      });
      return tree;
    },
  }
};
</script>
<style lang="scss">
.folder-tree > .customCardbody {
  padding-bottom: 15px !important;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}
.context-title {
  background-color: #e9e9e9;
}
</style>